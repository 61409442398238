.App {
  width: 100%;
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  font-family: 'Fahkwang', sans-serif;
}

.App-header {
  color: white;
  font-family: Fahkwang, sans-serif;
}

.header {
  font-size: 25px;
  font-family: Fahkwang, sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 18px 23px;
}

.header-icon {
  cursor: pointer;
  padding-top: 3px;
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.App-footer {
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  padding: 15px 0;
}

.header-menu-desktop {
  display: none;
}

@media(min-width: 1280px) {
  .header {
    justify-content: center;
  }
  .header-icon {
    display: none;
  }
  .header-menu-desktop {
    display: flex;
    text-align: center;
    padding-top: 20px;
    justify-content: space-between;
    margin: 0 auto;
    width: 25%;
  }
  .selected-item {
    color: #738f00;
  }
}

.header-title-animated {
  transform: translateY(15%);
  transition: transform 1.0s ease-in-out;
}

.header-title-animated.show {
  transform: translateY(-150%);
}
