.about {
  color: white;
}

.about-header {
  font-size: 30px;
  font-family: 'Fahkwang', sans-serif;
  line-height: 39px;
  text-align: center;
  padding: 56px 0 8px 0;
}

.about-header-description {
  font-size: 18px;
  font-family: 'Fahkwang', sans-serif;
  line-height: 23px;
  text-align: justify;
  padding: 3px 26px 20px 26px;
}

.about-description-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  padding: 25px 0;
}

.experience-title {
  font-family: 'Fahkwang';
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  padding: 10px 0;
}

.experience-date {
  font-family: 'Fahkwang';
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

.experience-description {
  font-family: 'Fahkwang';
  font-size: 17px;
  line-height: 24px;
  text-align: justify;
}

.experience-wrapper {
  padding: 5px 26px;
}

.about-contact-button {
  text-align: center;
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.about-picture {
  text-align: center;
  padding: 20px 0 0 0;
  img {
    border-radius: 120px;
    width: 250px;
    height: 250px;
  }
}

.about-contact-wrapper {
  text-align: center;
}

.about-contact-button {
  border: 2px solid white;
  border-radius: 25px;
  display: inline-block;
  height: 23px;
  width: 90px;
}

.about-header-image {
  display: none;
}

.about-header-contact-desktop {
  display: none;
}

.about-header-title-desktop {
  display: none;
}

@media (min-width: 1280px) {
  .experience-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .about-header-image {
    display: flex;
    position: absolute;
    z-index: -1;
    padding-left: 245px;
  }
  .about-header-wrapper {
    display: flex;
    padding: 15px 0;
    height: 750px;
  }
  .about-header {
    display: none;
  }
  .about-contact-wrapper {
    display: none;
  }
  .about-header-wrapper {
    display: flex;
    margin: 0 auto;
  }
  .about-header-description {
    position: absolute;
    background-color: #78687A;
    width: 370px;
    height: 443px;
    margin-top: 130px;
  }
  .about-header-wrapper-desktop {
    position: relative;
  }
  .about-header-title-desktop {
    display: inline-block;
    font-size: 30px;
    text-align: center;
    padding: 26px 0 24px 0;
    width: 100%;
  }
  .about-header-description-desktop {
    font-size: 21px;
    line-height: 23px;
    text-align: justify;
  }
  .about-header-contact-desktop {
    padding: 44px 0 0 53px;
    display: inline-block;
  }
  .about-contact-button {
    padding-top: 13px;
    height: 40px;
    width: 120px;
    border-radius: 55px;
    display: inline-block;
  }
  .about-header-description {
    font-size: 22px;
  }
}
