.App {
  width: 100%;
  min-height: 100vh;
  max-width: 1280px;
  margin: 0 auto;
  font-family: 'Fahkwang', sans-serif;
}

.App-header {
  color: white;
  font-family: Fahkwang, sans-serif;
}

.body-image-wrapper {
  text-align: center;
  padding-top: 25px;
}

.body-image {
  text-align: center;
  width: 100%;
  img {
    width: 200px;
    height: 200px;
    border-radius: 300px;
  }
}

.body {
  padding-top: 15px;
  color: white;
  text-align: center;
}

.body-services-title {
  font-weight: 400;
  font-size: 30px;
  font-family: "Fahkwang", sans-serif;
  line-height: 39px;
  padding-bottom: 10px;
}

.body-services-points {
  padding: 0 37px;
  text-align: left;
  font-family: "Fahkwang", sans-serif;
}

.body-services-point {
  display: list-item;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  padding: 5px 0;
}

.body-quote {
  font-family: "Fahkwang", sans-serif;
  padding: 15px 37px;
}

.icon-images {
  display: flex;
  justify-content: space-around;
  img {
    width: 50px;
    height: 50px;
  }
}

.body-quote-wrapper {
  font-family: "Fahkwang", sans-serif;
  font-size: 18px;
  border: 2px solid white;
  border-radius: 15px;
  width: 100%;
  padding: 20px 0;
}

.quote-text {
  padding: 5px 25px;
}

.contact-wrapper-home {
  font-family: "Fahkwang", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  padding: 25px 0;
}

.email-wrapper {
  font-family: "Fahkwang", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 39px;
  padding: 10px 0 80px 0;
}


@media(min-width: 1280px) {
  .body-image {
    img {
      width: 350px;
      height: 350px;
      border-radius: 300px;
    }
  }
  .body-quote-wrapper {
    width: 55%;
    margin: 0 auto;
  }
  .body-services-points {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .body-services-point {
    text-align: unset;
  }
  .contact-wrapper-home {
    display: grid;
    grid-template-columns: 1fr;
  }
}
