.contact-wrapper {
  color: white;
  display: grid;
  grid-template-columns: 1fr;
}
.contact-title {
  text-align: center;
  padding: 10px 0 30px 0;
}

.contact-field {
  font-family: 'Fahkwang', sans-serif;
  color: white;
  padding: 10px 26px;
  label {
    color: white;
  }
  input {
    color: white;
  }
  textarea {
    color: white;
  }
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.submit-button-wrapper {
  text-align: center;
  padding-top: 15px;
}

.submit-button {
  border: 2px solid white;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  height: 45px;
  width: 90px;
}


@media (min-width: 1280px) {
  .contact-wrapper {
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .contact-title {
    padding-top: 90px;
  }
  .first-two-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
